import { useTranslation } from '@loveholidays/phrasebook';
import React, { useEffect, useState } from 'react';

import { useAppContext } from '@Client/context/contexts';
import { ClassNameProps } from '@ComponentProps';
import { useBreakpoint } from '@Core/useBreakpoint';

export interface TripAdvisorReviewsProps extends ClassNameProps {
  tripAdvisorId?: number;
}

export const TripAdvisorReviews: React.FC<TripAdvisorReviewsProps> = ({
  tripAdvisorId = 0,
  className,
}) => {
  const { site } = useAppContext();
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();
  const partnerId = '557533A32EF34E219DC32F6429AF6744';

  const [tripUrl, setTripUrl] = useState('');

  useEffect(() => {
    // eslint-disable-next-line max-len
    setTripUrl(
      `https://${
        site.tripadvisorDomain
      }/WidgetEmbed-cdspropertydetail?display=true&forceMobile=${isMobile()}&locationId=${tripAdvisorId}&partnerId=${partnerId}`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <iframe
      data-id="trip-advisor-content"
      src={tripUrl}
      title={t('hotel.quicklink.tripadvisor')}
      loading="lazy"
      className={className}
      sx={{
        border: 0,
        width: '100%',
        height: '85vh',
      }}
    />
  );
};
